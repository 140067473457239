<template>
  <v-row justify="center">
    <v-dialog v-model="show" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <v-card color="#E3F2FD">
        <v-toolbar dark color="#E3F2FD" elevation="0">
          <!-- <v-toolbar-title>Settings</v-toolbar-title> -->
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon color="#1976D2" @click="$emit('closedialog')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pa-0" style="height: 100vh">
          <!-- <v-container fluid> -->
          <v-layout row wrap justify-center fill-height>
            <v-layout
              row
              wrap
              align-center
              justify-center
              style="background: #1976D2; background: linear-gradient(to bottom,#E3F2FD, #1976D2);"
            >
              <v-flex lg12>
                <v-layout row wrap align-center justify-center>
                  <v-row justify="center">
                    <v-img :src="require('@/assets/img/logo_onebox-horizontal-png.png')" max-width="400" max-height="200" />
                    <v-img :src="require('@/assets/img/inetlogo_1.png')" max-width="300" max-height="200" />
                  </v-row>
                </v-layout>
                <br />
                <br />
                <br />
                <br />
                <v-layout row wrap align-center justify-center>
                  <v-flex xs12 lg12 class="text-center" pa-6>
                    <br />
                    <span style="color:white; font-size: 24px;">สนใจซื้อพื้นที่ระบบ One Box กรุณาติดต่อ</span>
                    <br />
                    <br />
                    <span style="color: white; font-size: 20px;"
                      ><v-icon color="white">phone</v-icon> เบอร์โทรศัพท์ : 02-257-7111
                    </span>
                    <br />
                    <br />
                    <span style="color: white; font-size: 20px;"
                      ><v-icon color="white">email</v-icon> email : marketing@inet.co.th</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-layout>
          <!-- </v-container> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  data() {
    return {};
  },
  props: ["show"],
  methods: {},
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageMax",
      "dataStorageUsage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          console.log("this.show", this.show);
        }
        return this.show;
      },
    },
  },
};
</script>
